import Long from 'long'
import protobuf from 'protobufjs'

import { BrowserInfo } from '../browser'
import { commands, globals } from '../messages/protobuf'
import runtimeConfig from '../RuntimeConfig'
import { resolveDeviceUUID } from './resolveDeviceUUID'

import CommandContext = commands.CommandContext
import ApplicationType = globals.ApplicationType
import AppVersion = globals.AppVersion
import Device = globals.Device
import DeviceType = globals.DeviceType
import PageType = globals.PageContext.PageType

protobuf.util.Long = Long
protobuf.configure()

const createDefaultAppVersion = () => {
  return new AppVersion({
    appType: ApplicationType.WEBAPP,
    appName: { value: runtimeConfig().publicRuntimeConfig.hostname },
    versionNumber: { value: runtimeConfig().publicRuntimeConfig.buildID },
    buildNumber: { value: runtimeConfig().publicRuntimeConfig.buildNumber },
  })
}

const createDefaultDevice = (browserInfo: BrowserInfo, themePreference: 'light' | 'dark' | undefined): Device => {
  const device = new Device({
    deviceUuid: { value: resolveDeviceUUID() },
    deviceType: DeviceType.UNKNOWN_DEVICE,
    data: themePreference ? { theme_preference: themePreference } : {},
  })
  // Fill the user-agent
  if (typeof navigator !== 'undefined' && navigator.userAgent) device.userAgent = { value: navigator.userAgent }

  // Fill the device type
  if (browserInfo.ios) {
    device.deviceType = browserInfo.isTablet ? DeviceType.IPAD : DeviceType.IPHONE
  } else if (browserInfo.android) {
    device.deviceType = browserInfo.isTablet ? DeviceType.ANDROID_TABLET : DeviceType.ANDROID
  } else if (browserInfo.isDesktop) {
    device.deviceType = DeviceType.DESKTOP
  }
  return device
}

export const createDefaultCommandContext = (
  browserInfo: BrowserInfo,
  themePreference: 'light' | 'dark' | undefined
): CommandContext =>
  Object.freeze(
    new CommandContext({
      session: {
        appVersion: createDefaultAppVersion(),
        device: createDefaultDevice(browserInfo, themePreference),
      },
      currentContext: {
        page: PageType.UNKNOWN_PAGE,
      },
      referrerContext: {
        page: PageType.UNKNOWN_PAGE,
      },
    })
  )
